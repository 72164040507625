// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/* =============================================================================
 *   Global
 * ========================================================================== */

// Based on: http://t3n.de/news/opentype-font-feature-setting-531985/?utm_content=buffer356b0&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer
html{
  font-size: $font_size; // we need to define it here to use the rem unit
  line-height: $line-height;
  -webkit-text-size-adjust: 100%;
  
  /* Ligaturen aktivieren */
  -moz-font-feature-settings: "liga=1, dlig=1";
  -ms-font-feature-settings: "liga", "dlig";
  -webkit-font-feature-settings: "liga", "dlig";
  -o-font-feature-settings: "liga", "dlig";
  font-feature-settings: "liga", "dlig";

  /* Use OpenType Breaks */
  -moz-font-feature-settings:"frac" 1;
  -moz-font-feature-settings:"frac=1";
  -ms-font-feature-settings:"frac" 1;
  -o-font-feature-settings:"frac" 1;
  -webkit-font-feature-settings:"frac" 1;
  font-feature-settings:"frac" 1;

  /* Use real small caps */
  -moz-font-feature-settings:"smcp" 1;
  -moz-font-feature-settings:"smcp=1";
  -ms-font-feature-settings:"smcp" 1;
  -o-font-feature-settings:"smcp" 1;
  -webkit-font-feature-settings:"smcp" 1;
  font-feature-settings:"smcp" 1;
}

body {
  font-family: $font_first;
  font-weight: $font_weight_normal;

  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern=1";
  font-kerning: normal;
  
  /* Silbentrennung */
  // #webksde-tf: Auf Kundenwunsch deaktiviert.
/*  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;*/
}

/* =============================================================================
 *   Headlines
 * ========================================================================== */
/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */

h1,
h2,
h3,
h4 { 
  font-family: $font_first;
  color:$text_color; 
  font-weight:$font_weight_normal;
}

h1 a,
h2 a,
h3 a,
h4 a{ color:$text_color; text-decoration:none; }

h1 {
  font-size: 1.65em;
  margin: 0 0 0.67em 0;
  a{
    color:$highlight;
  }
}


h2 {
  font-size: 22px;
  margin: 0.83em 0;
}
h3 {
  font-size: 1.17em;
  margin: 1em 0;
}
h4 {
  font-size: 1em;
  margin: 1.33em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}
h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

#page-title {
  margin-top: 0;
}

/* =============================================================================
 *   Content Elements
 * ========================================================================== */
p{
  margin:0 0 $paragraph_spacing 0;
  &:empty{
    display:none;
  }
}

pre,
code,
tt,
samp,
kbd,
var {
  font-family: $font_code;
}

b,
strong {
  font-weight: $font_weight_bold;
}

small {
  font-size: $font_small;
}

pre {
  margin: 0 0 1.5em;
}

hr{
  height:0;
  border-width:1px 0 0 0;
  border-style:solid;
  border-color: $grey;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}


sup {
  top: -0.5em;
}


sub {
  bottom: -0.25em;
}

/* Selection */
::selection {
	background: $selection_color; /* Safari */
  color:$selection_color_foreground;
	}
::-moz-selection {
	background: $selection_color; /* Firefox */
  color:$selection_color_foreground;
}